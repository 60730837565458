import React from 'react'
import './style.scss'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from "gatsby"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faFacebook, faXTwitter } from '@fortawesome/free-brands-svg-icons'

import { Link } from "gatsby"
 
function Footer(props) {

  const { wordpressAcfOptions } = useStaticQuery(
    graphql`
      query {
        wordpressAcfOptions {
          options {
            footer_image {
              localFile {
                ...imgStandard
              }
            }
          }
        }
      }
    `
  )
  
  const footerImage = wordpressAcfOptions.options.footer_image?.localFile?.childImageSharp.fluid

  return (
		<footer id='master-footer' className='c4 grid-12 fs-85'>

			<div className="decor"></div>

			<div className="main-wrap">

				{ footerImage ?
					<div className="aspect-ratio footer-img span-12-mobile">
						<Img fluid={footerImage}
							imgStyle={{objectFit: 'cover'}}
							objectPosition='50% 50%'
							loading='eager'
							backgroundColor="#ccc"
							fadeIn={true}
							alt='MRU Facility'
						/>
					</div>
				: null }

				<div className="inner-wrap span-12-mobile">

					<div className="intro">
						<h2 className="h1">
							Saber Que Se Puede,<br/>
							Believe you Can!
						</h2>
					</div>
					
					<div className="grid-12 gap-4 container">

						<div className="span-4 span-12-mobile">
							<div className="logo-wrap">
								<div className="aspect-ratio logo"></div>
							</div>
							<p>For for over 25 years, Miami Regional University (MRU) has provided its students with a high quality and personalized level of education.</p>
							<p>MRU’s niche is to develop solutions for our community’s needs with a mission to educate and develop individuals to achieve their career potential.</p>
						</div>

						<div className="span-4 span-12-mobile padd-top-2">

							<h3 className='h6'>Find Us</h3>
							<address className="font4 fs-125">
								700 S. Royal Poinciana Blvd. Suite 100
								Miami Springs, FL 33166	
							</address>
							<p className="font4 fs-125">
								<a className="colored" href="mailto:info@mru.edu">info@mru.edu</a><br/>
								<a href="tel:3054429223">305 442 9223</a>
							</p>


							<h3 className='h6'>Social Media</h3>
							<div className='footer-icons'>
								<a href='https://www.facebook.com/miamiregionaluniversity/' title='facebook' className='hint--rounded hint--top' data-hint='@mru' target='_blank' rel='noopener noreferrer'><FontAwesomeIcon icon={faFacebook} /></a>
								<a href='https://x.com/MrUedu' className='hint--rounded hint--top' title='X' data-hint='@mru' target='_blank' rel='noopener noreferrer'><FontAwesomeIcon icon={faXTwitter} /></a>
								<a href='https://www.instagram.com/miamiregionaluniversity/' title='instagram' className='hint--rounded hint--top' data-hint='@mru' target='_blank' rel='noopener noreferrer'><FontAwesomeIcon icon={faInstagram} /></a>
							</div>

						</div>

						<div className="span-4 span-12-mobile padd-top-2">

							<h3 className='h6'>Useful links</h3>
							<div className="grid-12 font4 fs-125">
								<ul className="span-6 span-12-mobile">
									<li><Link to="/">Home</Link></li>
									<li><Link to="/welcome">About</Link></li>
									<li><Link to="/academics">Academics</Link></li>
									<li><Link to="/career-services">Careers</Link></li>
								</ul>
								<ul className="span-6 span-12-mobile">
									<li><Link to="/admission-requirements">Admissions</Link></li>
									<li><Link to="/consumer-information">Consumer Info</Link></li>
									<li><Link to="/privacy-policy">Privacy Policy</Link></li>
								</ul>
							</div>

							<h3 className='h6'>Campus portal</h3>
							<p className="font4 fs-125">
                <a href="https://sisstudentsts-291.campusnet.net/Login.aspx?ReturnUrl=%2f%3fwa%3dwsignin1.0%26wtrealm%3dhttps%253a%252f%252fportal.mru.edu%252f%26wct%3d2021-07-26T16%253a19%253a19Z%26wctx%3drm%253d0%2526id%253dpassive%2526ru%253dsecure%252fstudent%252fstuportal.aspx%26wreply%3dhttps%253a%252f%252fportal.mru.edu%252f%26AppType%3dPortal%26Role%3dSTUDENT&wa=wsignin1.0&wtrealm=https%3a%2f%2fportal.mru.edu%2f&wct=2021-07-26T16%3a19%3a19Z&wctx=rm%3d0%26id%3dpassive%26ru%3dsecure%2fstudent%2fstuportal.aspx&wreply=https%3a%2f%2fportal.mru.edu%2f&AppType=Portal&Role=STUDENT">Student Portal</a>,
                <br/>
                <a href="https://login.microsoftonline.com/common/oauth2/authorize?client_id=4345a7b9-9a63-4910-a426-35363201d503&redirect_uri=https%3A%2F%2Fwww.office.com%2Flanding&response_type=code%20id_token&scope=openid%20profile&response_mode=form_post&nonce=637629131999306084.NmM2MzdiOGYtY2JkMi00NzllLThlMDQtZDY1Yjg3YzQ4ZjhhYjAwMzc4ZjYtN2ZkYS00NzkzLTlkYzEtOGNiOWY2ZjBiMWM0&ui_locales=en-US&mkt=en-US&state=JAYec0_rv5QNeww0gjwZA5utU43_GZxSRrwupzckXRykslTlUOKwTHBWQ3mB-p99ohbsfhAogamtBPFqR7Ks-Jeq5rgZFeTCm4FxioEfKwt5p8RHEJBK9KN33QLhsRYSHiKwNMMeTMmwlf3QeVZB17V9WXaPtHuOId9Ad-56NbaYwFTkmixep7gZ6zDyhv8mAzDT0DUbZpopvp9adDOXlnlFEQNn6k4hqbrlbZrfHnXp-lyhArG0HxDGzGSLvqZGU_VIa_AOjSSg26d-9ua07Q&x-client-SKU=ID_NETSTANDARD2_0&x-client-ver=6.8.0.0#"> Student Email Access</a>
              </p>
						</div>

					</div>

				</div>

			</div>

			<div className='copy'>
				<p>&copy; Copyright 2025, All Rights Reserved, Miami Regional University.     Legal    |    Privacy </p>
				<p><span className="colored">Site designed by</span> <a className="fs-125" href="https://www.deepsleepstudio.com" alt="DeepSleep Studio, a Design and Public Relations agency." target="_blank" rel="noreferrer">ds/s</a>.</p>
			</div>

		</footer>
  )
}

export default Footer